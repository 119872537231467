export const parseCookie = (str: any) =>
    str
        .split(';')
        .map((v: String) => v.split('='))
        .reduce((acc: String, v: Array<String>) => {
            acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
            return acc;
        }, {});

export const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

/**
 * @param {String} str - a string to be formatted.
 */
 export const formatMaskCPF = (str: String) => {
    let a = str.replace(/[^\d]+/g, ''); // remove non digit chars.

    if(a.length>9) a = a.replace(/(\d{3})(\d{3})(\d{3})(\d)/, '$1.$2.$3-$4');
    else if(a.length > 6) a = a.replace(/(\d{3})(\d{3})(\d)/, '$1.$2.$3');
    else if(a.length > 3) a = a.replace(/(\d{3})(\d)/, '$1.$2');

    return a;
  }