export const TRANSLATIONS_PT_BR = {
  //General
  email: 'E-mail',
  cancel: 'Cancelar',
  login: 'Login',
  logout: 'Logout',
  server_error: 'Ocorreu um problema. Tente mais tarde ou entre em contato.',

  //Landing page
  landing_info_title: 'Receba sua encomenda em qualquer armário',
  landing_info_subtitle1: 'Não quer ficar esperando pelo entregador?',
  landing_info_subtitle2:
    'Então reserve a porta de um armário e assim que sua encomenda for entregue, você pode buscar quando for mais conveniente para você!',
  reserve_locker_button: 'Reservar porta de armário',

  //Landing page --- How does it work?
  how_does_it_work_title: 'Como funciona?',
  instruction1: 'Cadastre-se ou faça login',
  instruction2:
    'Escolha o armário em que deseja receber a encomenda, o tamanho da porta e o email que deve receber a senha de retirada',
  instruction3: 'Complete o pagamento',
  instruction4:
    'A senha para entregua, assim como o endereço do armario estará disponivel pra você',
  instruction5:
    'Pronto! Assim que a encomenda chegar, o email escolhido anteriormente vai receber a senha para a retirada',

  //Landing page --- Pricing
  pricing_title: 'Quanto custa?',
  pricing_description: 'Preço único: R$4,99',

  //Landing page --- About
  about_title: 'O que é Pier10?',
  about_description:
    'Pier10 é um serviço onde você pode receber suas encomendas de maneira segura e independende da sua disponibilidade. Nós usamos tecnologia e uma rede de armários para garantir que sua encomenda esteja segura e ao mesmo tempo te de a flexibilidade para retira-la quando você puder. Utilize para todas as suas encomendas online ou qualquer entrega. Registre-se e use todas as vantagens que Pier10 te oferece.',

  //Reserve page
  reserve_title: 'Reservar porta',
  select_location: 'Selecione em qual armário você deseja reservar a porta',
  locker_location: 'Armário em',
  no_locker_selected: 'Nenhum armário selecionado',
  additional_details: 'Informações adicionais',
  select_locker_size: 'Selecione um tamanho',
  select_locker_size_helper:
    'Uma porta pode ter diferentes tamanhos. Escolha entre grande, media ou pequena.',
  notification_email: 'Email para receber senha de retirada',
  reserve_button: 'Reservar',
  'reserve.sizes': 'Tamanhos',
  'reserve.selected_station': 'Estação selecionada',
  'reserve.unavailable': 'indisponível',
  'reserve.payment_error': 'Erro ao concluir o pagamento',
  'reserve.email_sent': 'E-mail enviado',
  'reserve.resend_verification_email': 'Reenviar email de verificação',
  'reserve.select_station': 'Selecionar armário',

  //Locker sizes
  SMALL: 'Pequeno',
  MEDIUM: 'Médio',
  LARGE: 'Grande',

  //Reserve page --- Payment modal
  'reserve.pay_now': 'Pague agora',
  confirm_purchase: 'Confirmar reserva',
  payment_details: 'Detalhes de pagamento',
  summary: 'Resumo',
  locker_address: 'Endereço do armário',
  locker_size: 'Tamanho da porta',
  email_code_to: 'Email para retirada',
  cost: 'Custos',
  taxes: 'Taxas',
  total: 'Total',

  //Confirmation page
  reservation_confirmed: 'Reserva confirmada',
  congratulations: 'Parabéns!',
  confirmation_details:
    'O pagamento foi aprovado e a reserva de sua porta foi feita com sucesso! Em poucos instantes você receberá o recibo e o codigo de entrega por email. Com esse código a entrega pode ser efetuada na porta alocada para você.',
  confirmation_code: 'O codigo para entrega é',
  return_to_home: 'Retornar para página inicial',

  //Register
  register_title: 'Cadastrar-se',
  first_name_label: 'Nome',
  last_name_label: 'Sobrenome',
  password_label: 'Senha',
  confirm_password_label: 'Confirme a senha',
  create_account: 'Criar conta',
  'register.already_has_account': 'Ja é cadastrado?',
  'register.login_here': 'Fazer login',
  'register.first_name_error': 'Nome não pode estar vazio',
  'register.last_name_error': 'Sobrenome não pode estar vazio',
  'register.email_error': 'Email invalido',
  'register.cpf_empty': 'CPF não pode estar vazio',
  'register.password_empty': 'Senha precisa ser preenchida',
  'register.password_match_error': 'Senhas não são iguais',
  'register.registration_successful':
    'Seu cadastro foi feito com sucesso! Verifique seu e-mail para ter acesso a todas as funcionalidades.',
  'register.email_sent': 'E-mail enviado',
  'register.resend_verification_email': 'Reenviar e-mail de verificação',

  //Verify email
  email_verify: 'Verificar',
  'verification.no_url_provided': 'No verification url was provided',
  'verificiation.verifying': 'Verifying email address',
  'verification.success': 'Verification successful',
  'verification.success_detail':
    'Your e-mail has been verified. You can now log in and use all of the features.',
  'verification.failure': 'Your email could not be verified',
  'verification.failure_detail': 'Error verifying your e-mail.',

  //Navbar
  'navbar.open_settings': 'Configurações',
  'navbar.how_it_works': 'Como functiona',
  'navbar.pricing': 'Preço',
  'navbar.about': 'Sobre',

  //Login
  'login.has_no_account': 'Não é cadastrado?',
  'login.register_here': 'Cadastre-se aqui',
  'login.email_not_valid': 'E-mail invalido',
  'login.password_empty': 'Senha precisa ser preenchida',

  //Map
  'map.error_loading': 'Erro ao carregar o mapa',
}
