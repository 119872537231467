import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import i18n from "i18next";

export interface LanguageState {
  language: string;
}

export const initialState: LanguageState = {
  language: "pt_br"
};

export const languageSlice = createSlice({
  name: 'language',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
      i18n.changeLanguage(action.payload);
    }
  },
})

export const { setLanguage} = languageSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const getLanguage = (state: RootState) => state.language.language;

export default languageSlice.reducer;