import { useRoutes } from 'react-router-dom';
import routes from './router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline, Modal } from '@mui/material';
import Navbar from 'src/content/pages/Components/Navbar';
import Register from 'src/content/pages/Authentication/Register';
import Login from 'src/content/pages/Authentication/Login';

import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setShowRegisterModal } from 'src/redux/features/auth/auth';

const App = () => {

  const dispatch = useAppDispatch();

  const content = useRoutes(routes);
  const showRegisterModal = useAppSelector(state => state.user.showRegisterModal);
  const registerModalType = useAppSelector(state => state.user.registerModalType);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Navbar />
        {content}

        <Modal
          open={showRegisterModal}
          onClose={() => dispatch(setShowRegisterModal(false))}
          aria-labelledby="modal-register--or-login-user"
          aria-describedby="register-or-login-user"
          sx={{ overflowY: 'scroll' }}
        >
          <div>
            {registerModalType === "REGISTER" ? <Register /> : <Login />}
          </div>
        </Modal>

      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
