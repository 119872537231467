import { Helmet } from 'react-helmet-async';
import { useRef, useState } from 'react';

import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Container, Grid, Card, CardHeader, CardContent, Divider, Button, Alert, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { User, IUser, resendVerification } from 'src/services/authentication';

import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { setShowRegisterModal, setRegisterModalType, saveUser } from 'src/redux/features/auth/auth';

import { useTranslation } from 'react-i18next';
import "src/translations/i18n";

import { validateEmail, formatMaskCPF } from 'src/utils/general';

import './styles/register.scss';

function Register() {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector(state => state.user.userInfo);

  const firstName = useRef(null);
  const lastName = useRef(null);
  const email = useRef(null);
  const password = useRef(null);
  const passwordConfirm = useRef(null);
  const [cpf, setCPF] = useState("");

  const [creatingAccount, setCreatingAccount] = useState(false);

  const [showVerifyEmail, setShowVerifyEmail] = useState(false);

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [cpfError, setCpfError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] = useState("");
  const [serverError, setServerError] = useState("");

  const [emailSending, setEmailSending] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const user: User = new User();

  const cancelClick = () => {
    dispatch(setShowRegisterModal(false));
  }

  const createAccount = async () => {
    if (!validateForm()) return;

    setCreatingAccount(true);

    const newUser: IUser = {
      firstName: firstName.current.value,
      lastName: lastName.current.value,
      email: email.current.value,
      password: password.current.value,
      passwordConfirmation: passwordConfirm.current.value,
      documentType: 'CPF',
      documentNumber: cpf
    }

    const registerResp = await user.register(newUser);

    if (!registerResp) {
      setServerError(t("server_error"))
    }
    else if (registerResp.error) {
      setServerError(registerResp.details);
    }
    else if (registerResp.token) {
      setShowVerifyEmail(true);

      dispatch(saveUser({
        firstName: firstName.current.value,
        lastName: lastName.current.value,
        email: email.current.value,
        token: registerResp.token
      }));
    }

    setCreatingAccount(false);
  }

  const validateForm = () => {

    let hasNoErrors = true;

    if (firstName.current.value === "") {
      setFirstNameError(t("register.first_name_error"));
      hasNoErrors = false;
    }
    else setFirstNameError("");
    if (lastName.current.value === "") {
      setLastNameError(t("register.last_name_error"));
      hasNoErrors = false;
    }
    else setLastNameError("");
    if (!validateEmail(email.current.value)) {
      setEmailError(t("register.email_error"));
      hasNoErrors = false;
    }
    else setEmailError("");
    if (cpf === "") {
      setCpfError(t("register.cpf_empty"));
      hasNoErrors = false;
    }
    else setCpfError("");
    if (password.current.value === "") {
      setPasswordError(t("register.password_empty"));
      hasNoErrors = false;
    }
    else setPasswordError("");
    if (password.current.value !== passwordConfirm.current.value) {
      setPasswordConfirmationError(t("register.password_match_error"));
      hasNoErrors = false;
    }
    else setPasswordConfirmationError("");

    return hasNoErrors;
  }

  const sendVerificationEmail = async () => {
    setEmailSending(true);

    const verificationEmailResp = await resendVerification(userInfo.id ,userInfo.token);
    if (verificationEmailResp.details) {
      setEmailSent(false);
    }
    else setEmailSent(true);

    setEmailSending(false);
  }

  return (
    <>
      <Helmet>
        <title>{t("register_title")}</title>
      </Helmet>
      <PageTitleWrapper />
      <Container maxWidth="sm">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={10}>
            <Card sx={{ position: 'relative' }}>
              <CardHeader title={t("register_title")} />
              <CloseIcon className='closeButton' onClick={() => cancelClick()} />
              <Divider />
              {
                showVerifyEmail || (userInfo.isLoggedIn && !userInfo.isVerified) ?
                  <div className="verifyEmailText">
                    <div><CheckCircleOutlineIcon sx={{ fontSize: 50, color: "#4BB543" }} /></div>
                    <div className='verifyEmailDescription'>{t("register.registration_successful")}</div>
                    <hr />
                    <div>
                      <Button variant="outlined" disabled={emailSending || emailSent} onClick={() => sendVerificationEmail()}>
                        {emailSending ?
                          <CircularProgress /> :
                          emailSent ? t("register.email_sent") :
                            t("register.resend_verification_email")}
                      </Button>
                    </div>
                  </div>
                  :
                  <CardContent>
                    {
                      serverError !== "" && <Alert
                        style={{ marginBottom: '10px' }}
                        severity="error"
                      >
                        {t(serverError)}
                      </Alert>
                    }
                    <Box
                      component="form"
                      sx={{
                        '& .MuiTextField-root': { m: 1, width: '96%' },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        required
                        error={firstNameError !== ""}
                        id="first-name"
                        inputRef={firstName}
                        fullWidth
                        label={t("first_name_label")}
                        helperText={firstNameError}
                      />
                      <TextField
                        required
                        error={lastNameError !== ""}
                        id="last-name"
                        inputRef={lastName}
                        fullWidth
                        label={t("last_name_label")}
                        helperText={lastNameError}
                      />
                      <TextField
                        required
                        error={emailError !== ""}
                        id="e-mail"
                        fullWidth
                        inputRef={email}
                        maxRows={1}
                        label="E-mail"
                        helperText={emailError}
                      />
                      <TextField
                        required
                        error={cpfError !== ""}
                        id="cpf-input"
                        value={cpf}
                        onChange={(e) => setCPF(formatMaskCPF(e.target.value))}
                        fullWidth
                        label="CPF"
                        helperText={cpfError}
                      />
                      <TextField
                        error={passwordError !== ""}
                        required
                        id="password-input"
                        inputRef={password}
                        fullWidth
                        maxRows={1}
                        label={t("password_label")}
                        type="password"
                        autoComplete="current-password"
                        helperText={passwordError}
                      />
                      <TextField
                        error={passwordConfirmationError !== ""}
                        required
                        id="password-input-confirm"
                        inputRef={passwordConfirm}
                        fullWidth
                        maxRows={1}
                        label={t("confirm_password_label")}
                        type="password"
                        autoComplete="current-password"
                        helperText={passwordConfirmationError}
                      />
                      <div className='createAccountButtonContainer'>
                        <Button disabled={creatingAccount} className='createAccountButton' variant="contained" onClick={createAccount}>
                          {t("create_account")}
                        </Button>
                      </div>

                      <div className='switchModals'>{t("register.already_has_account")} <span onClick={() => dispatch(setRegisterModalType("LOGIN"))}>{t("register.login_here")}</span></div>
                    </Box>
                  </CardContent>
              }

            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Register;
