import { Helmet } from 'react-helmet-async';
import { useRef, useState } from 'react';

import PageTitleWrapper from 'src/components/PageTitleWrapper';

import { Container, Grid, Card, CardHeader, CardContent, Divider, Button, Alert, Box, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { User } from 'src/services/authentication';

import { useAppDispatch } from 'src/redux/hooks'
import { saveUser, setShowRegisterModal, setRegisterModalType } from 'src/redux/features/auth/auth';

import { useTranslation } from 'react-i18next';
import "src/translations/i18n";

import { validateEmail } from 'src/utils/general';

import '../Register/styles/register.scss';

function Login() {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const email = useRef(null);
  const password = useRef(null);
  const user: User = new User();

  const [serverError, setServerError] = useState("");
  const [signingIn, setSigningIn] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const cancelClick = () => {
    dispatch(setShowRegisterModal(false));
  }

  const performLogin = async () => {

    if (!validateForm()) return;

    setSigningIn(true);

    // Call business logic to login an account
    const loginResp = await user.login(email.current.value, password.current.value);

    if (!loginResp) {
      setServerError(t("server_error"))
    }
    else if (loginResp.token) {
      dispatch(saveUser({
        firstName: loginResp.firstName,
        lastName: loginResp.lastName,
        email: loginResp.email,
        token: loginResp.token,
        id: loginResp.id
      }))
      dispatch(setShowRegisterModal(false));
    }
    else if (loginResp.error) {
      setServerError(loginResp.details);
    }

    setSigningIn(false);
  }

  const validateForm = () => {

    let hasNoErrors = true;

    if (!validateEmail(email.current.value)) {
      setEmailError(t("login.email_not_valid"));
      hasNoErrors = false;
    }
    else setEmailError("");
    if (password.current.value === "") {
      setPasswordError(t("login.password_empty"));
      hasNoErrors = false;
    }
    else setPasswordError("");

    return hasNoErrors;
  }

  return (
    <>
      <Helmet>
        <title>{t("login")}</title>
      </Helmet>
      <PageTitleWrapper />
      <Container maxWidth="sm">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={10}>
            <Card sx={{ position: 'relative'}}>
              <CardHeader title={t("login")} />
              <CloseIcon className='closeButton' onClick={() => cancelClick()} />
              <Divider />
              <CardContent >
                {
                  serverError !== "" && <Alert
                    style={{ marginBottom: '10px' }}
                    severity="error"
                  >
                    {serverError}
                  </Alert>
                }
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '96%' },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <div>
                    <TextField
                      required
                      id="e-mail"
                      fullWidth
                      inputRef={email}
                      maxRows={1}
                      label={t("email")}
                      error={emailError !== ""}
                      helperText={emailError}
                    />
                    <TextField
                      required
                      id="password-input"
                      inputRef={password}
                      fullWidth
                      maxRows={1}
                      label={t("password_label")}
                      type="password"
                      autoComplete="current-password"
                      error={passwordError !== ""}
                      helperText={passwordError}
                    />
                    <div className='createAccountButtonContainer'>
                      <Button disabled={signingIn} className='createAccountButton' variant="contained" onClick={performLogin}>
                        {t("login")}
                      </Button>
                    </div>

                    <div className='switchModals'>{t("login.has_no_account")} <span onClick={() => dispatch(setRegisterModalType("REGISTER"))}>{t("login.register_here")}</span></div>
                  </div>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Login;
