import { Box, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import logoImg from 'src/img/logo.png';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        background-image: url(${logoImg});
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 70px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoSign = styled(Box)(
  () => `
        background-image: url(${logoImg});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 38px;
`
);

function Logo() {

  return (
    <Tooltip title="Pier10" arrow>
      <LogoWrapper to="/">
        <LogoSign />
      </LogoWrapper>
    </Tooltip>
  );
}

export default Logo;
