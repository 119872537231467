export const TRANSLATIONS_EN_US = {
  //General
  email: 'E-mail',
  cancel: 'Cancel',
  login: 'Login',
  logout: 'Logout',
  server_error: 'An error has occurred. Try again or contact us.',

  //Landing page
  landing_info_title: 'Receive your package in any locker station',
  landing_info_subtitle1:
    "Don't want to have to sit at home to wait for the postman?",
  landing_info_subtitle2:
    "Then let your package be sent to a packing station where you can get it whenever it's the most comfortable for you!",
  reserve_locker_button: 'Reserve locker',

  //Landing page --- How does it work?
  how_does_it_work_title: 'How does it work?',
  instruction1: 'Register yourself or login',
  instruction2:
    'Choose the locker station you want to receive your package, the size of the locker and the email where you want to be notified when the package arrives',
  instruction3: 'Complete your payment',
  instruction4:
    'Your locker station address as well as the delivery password will be available to you, so you can use for your personal delivery',
  instruction5:
    'Done! As soon as your package arrives, you will receive an email with your pickup password',

  //Landing page --- Pricing
  pricing_title: 'How much does it cost?',
  pricing_description: 'Unique pricing: R$4,99',

  //Landing page --- About
  about_title: 'What is PIER10?',
  about_description:
    'Pier10 is a service where you can receive your packages safely independent of your schedule. We use technology and a network of locker stations to guarantee your autonomy and that your package will be safely stored. Use it for all your online purchases or any delivery necessities. Register as a customer today and use all of the advantages of Pier10.',

  //Reserve page
  reserve_title: 'Reserve locker',
  select_location: 'Select where you want to reserve your locker',
  locker_location: 'Locker station at',
  no_locker_selected: 'No locker selected',
  additional_details: 'Additional details',
  select_locker_size: 'Select size',
  select_locker_size_helper:
    'A locker can have different sizes. Select a large, medium or small size',
  notification_email: 'Notification email for waiting package',
  reserve_button: 'Reserve',
  'reserve.sizes': 'Sizes',
  'reserve.selected_station': 'Selected station',
  'reserve.unavailable': 'unavailable',
  'reserve.payment_error': 'Error completing payment',
  'reserve.email_sent': 'Email sent',
  'reserve.resend_verification_email': 'Resend verification email',
  'reserve.select_station': 'Select station',

  //Locker sizes
  SMALL: 'Small',
  MEDIUM: 'Medium',
  LARGE: 'Large',

  //Reserve page --- Payment modal
  'reserve.pay_now': 'Pay now',
  confirm_purchase: 'Confirm Purchase',
  payment_details: 'Payment details',
  summary: 'Summary',
  locker_address: 'Locker address',
  locker_size: 'Locker size',
  email_code_to: 'Email code to',
  cost: 'Cost',
  taxes: 'Taxes',
  total: 'Total',

  //Confirmation page
  reservation_confirmed: 'Reservation confirmed',
  congratulations: 'Congratulations!',
  confirmation_details:
    'Your payment was succesful and your locker has been assigned. Shortly, you will receive an e-mail with your receipt and confimation code. With your confirmation code you can retrieve your items from the locker.',
  confirmation_code: 'Your confimation code is',
  return_to_home: 'Return to home',

  //Register
  register_title: 'Register',
  first_name_label: 'First name',
  last_name_label: 'Last name',
  password_label: 'Password',
  confirm_password_label: 'Confirm password',
  create_account: 'Create Account',
  'register.already_has_account': 'Already have an account?',
  'register.login_here': 'Log in here',
  'register.first_name_error': 'First name cannot be empty',
  'register.last_name_error': 'Last name cannot be empty',
  'register.email_error': 'Email address not valid',
  'register.cpf_empty': 'CPF cannot be empty',
  'register.password_empty': 'Password cannot be empty',
  'register.password_match_error': 'Passwords have to match',
  'register.registration_successful':
    'Your registration has been successful. Check your email to verify your account and access all functionalities.',
  'register.email_sent': 'E-mail sent',
  'register.resend_verification_email': 'Resend verification e-mail',

  //Verify email
  email_verify: 'Verify',
  'verification.no_url_provided': 'No verification url was provided',
  'verificiation.verifying': 'Verifying email address',
  'verification.success': 'Verification successful',
  'verification.success_detail':
    'Your e-mail has been verified. You can now log in and use all of the features.',
  'verification.failure': 'Your email could not be verified',
  'verification.failure_detail': 'Error verifying your e-mail.',

  //Navbar
  'navbar.open_settings': 'Open settings',
  'navbar.how_it_works': 'How it works',
  'navbar.pricing': 'Pricing',
  'navbar.about': 'About',

  //Login
  'login.has_no_account': "Don't have an account?",
  'login.register_here': 'Register here',
  'login.email_not_valid': 'E-mail address not valid',
  'login.password_empty': 'Password cannot be empty',

  //Map
  'map.error_loading': 'Error loading map',
}
