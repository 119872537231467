import { config } from 'src/config';

export interface IUser {
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly password: string;
    readonly passwordConfirmation: string;
    readonly documentType: string;
    readonly documentNumber: string;
}

interface IAuthenticationService {
    login(email: string, password: string): void;
}

export class User implements IAuthenticationService {

    async login(email: string, password: string) {

        const url = `${config.BASE_URL}/users/login`;

        const loginToken = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                password
            })
        }).then(res => res.json())
            .then(data => data)
            .catch(error => {
                console.log(error);
                return false;
            });

        return loginToken;
    }

    async register(user: IUser) {

        const url = `${config.BASE_URL}/users`;

        const registerResp = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        }).then(res => res.json())
            .then(data => data)
            .catch(error => {
                console.log(error);
                return false;
            });

        return registerResp;
    }
}

export const resendVerification = async (userId: string, token: string) => {

    const url = `${config.BASE_URL}/users/${userId}/resendVerification`;

    const resendEmail = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': ' Bearer ' + token
        }
    });

    const resendEmailIntent = await resendEmail.json();
    console.log(resendEmailIntent);
    return resendEmailIntent;
}

export const getEmailVerification = async (token: string) => {
    const tokenParsed = parseJwt(token);

    if(!tokenParsed) return {details: 'invalid token'};

    const url = `${config.BASE_URL}/users/${tokenParsed.id}/verify?token=${token}`;

    const verificationResp = await fetch(url)
        .then(res => res.json())
        .then(data => data)
        .catch((error) => {
            console.log(error);
            return false;
        });
    
    console.log(verificationResp);

    return verificationResp;
}

function parseJwt (token: string) {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    } catch (error) {
        console.log(error);
        return false;
    }
};

