import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { TRANSLATIONS_PT_BR } from './pt-br/translations'
import { TRANSLATIONS_EN_US } from './en-us/translations'

import { initialState as initialLanguageState } from '../redux/features/language/language'

i18n.use(initReactI18next).init({
  fallbackLng: 'en_us',
  resources: {
    en_us: {
      translation: TRANSLATIONS_EN_US,
    },
    pt_br: {
      translation: TRANSLATIONS_PT_BR,
    },
  },
})

i18n.changeLanguage(initialLanguageState.language)
