import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Logo from 'src/components/LogoSign'
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom'
import { useEffect, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import {
  setShowRegisterModal,
  setRegisterModalType,
  logoutUser,
} from 'src/redux/features/auth/auth'
import { setLanguage } from 'src/redux/features/language/language'
import { saveUser } from 'src/redux/features/auth/auth'
import { parseCookie } from 'src/utils/general'

import 'src/translations/i18n'
import { Select } from '@mui/material'

//const settings = ['Profile', 'Account', 'Dashboard'];

const ResponsiveAppBar = () => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation();

  const userInfo = useAppSelector((state) => state.user.userInfo)
  const language = useAppSelector((state) => state.language.language)

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  )

  const pages = [t("navbar.how_it_works"), t("navbar.pricing"), t("navbar.about")]

  const saveUserBrowser = useCallback(
    (userInfoCookie: any) => {
      dispatch(
        saveUser({
          firstName: userInfoCookie.firstName,
          lastName: userInfoCookie.lastName,
          email: userInfoCookie.email,
          token: userInfoCookie.token,
        }),
      )
    },
    [dispatch],
  )

  useEffect(() => {
    try {
      const checkCookie = parseCookie(document.cookie)

      if (checkCookie.userInfo) {
        const userInfoCookie = JSON.parse(checkCookie.userInfo)
        saveUserBrowser(userInfoCookie)
      }
    } catch (error) {}
  }, [saveUserBrowser])

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (userInfo.isLoggedIn) setAnchorElUser(event.currentTarget)
    else {
      dispatch(setRegisterModalType('LOGIN'))
      dispatch(setShowRegisterModal(true))
    }
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogoutUser = () => {
    handleCloseUserMenu()
    dispatch(logoutUser())
  }

  return (
    <AppBar position="static" style={{ backgroundColor: '#E34F10' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Logo />

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}>
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}

              <MenuItem>
                <FormControl variant="standard">
                  <Select
                    variant="standard"
                    id="language-select"
                    value={language}
                    label="Language"
                    disableUnderline
                    onChange={(e) => dispatch(setLanguage(e.target.value))}>
                    <MenuItem value={'en_us'}>EN-US</MenuItem>
                    <MenuItem value={'pt_br'}>PT-BR</MenuItem>
                  </Select>
                </FormControl>
              </MenuItem>
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'right',
              mr: 8,
            }}>
            {pages.map((page) => (
              <Button
                component={RouterLink}
                to={`/#${page}`}
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}>
                {page}
              </Button>
            ))}

            <Select
              style={{ marginLeft: '20px', color: 'white', marginTop: '5px' }}
              id="language-select"
              value={language}
              label="Language"
              onChange={(e) => dispatch(setLanguage(e.target.value))}
              variant="standard"
              disableUnderline
              IconComponent={() => null}>
              <MenuItem value={'en_us'}>
                <img
                  style={{ width: '20px' }}
                  className="navBarFlag"
                  alt="us flag"
                  src="https://upload.wikimedia.org/wikipedia/commons/8/88/United-states_flag_icon_round.svg"
                />
              </MenuItem>
              <MenuItem value={'pt_br'}>
                <img
                  style={{ width: '20px' }}
                  alt="brazil flag"
                  className="navBarFlag"
                  src="https://upload.wikimedia.org/wikipedia/commons/4/4a/Brazilian_flag_icon_round.svg"
                />
              </MenuItem>
            </Select>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={userInfo.isLoggedIn ? t("navbar.open_settings") : t("login")}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={userInfo.firstName}
                  src="/static/images/avatar/2.jpg"
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              {/*settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))*/}

              <MenuItem onClick={() => handleLogoutUser()}>
                <Typography textAlign="center">{t("logout")}</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default ResponsiveAppBar
