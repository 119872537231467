import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const saveCookie = (action: PayloadAction<Object>) => {
  const expiryHours = 6;
  const dateNow = new Date();
  dateNow.setTime(dateNow.getTime() + (expiryHours * 60 * 60 * 1000));
  let expires = "expires=" + dateNow.toUTCString();

  document.cookie = `userInfo=${JSON.stringify(action.payload)}; expires=${expires}; path=/;`;
}

const deleteCookie = () => {
  document.cookie = `userInfo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export interface UserState {
  userInfo: any;
  showRegisterModal: boolean;
  registerModalType: String;
}

const initialState: UserState = {
  userInfo: {
    isLoggedIn: false,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    token: undefined,
    id: undefined
  },
  showRegisterModal: false,
  registerModalType: "REGISTER",
};

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    saveUser: (state, action: PayloadAction<any>) => {
      if(state.userInfo.token !== action.payload.token ) saveCookie(action);
      state.userInfo = action.payload;
      state.userInfo.isLoggedIn = true;
    },
    logoutUser: (state) => {
      state.userInfo = initialState.userInfo;
      deleteCookie();
    },
    setShowRegisterModal: (state, action: PayloadAction<boolean>) => {
      state.showRegisterModal = action.payload
    },
    setRegisterModalType: (state, action: PayloadAction<String>) => {
      state.registerModalType = action.payload
    }
  },
})

export const { saveUser, setShowRegisterModal, setRegisterModalType, logoutUser } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUser = (state: RootState) => state.user.userInfo;
export const getShowRegisterModal = (state: RootState) => state.user.showRegisterModal;
export const getRegisterModalType = (state: RootState) => state.user.registerModalType;

export default userSlice.reducer;