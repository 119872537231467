import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages
const Overview = Loader(lazy(() => import('src/content/overview')));

//Authentication
const VerifyEmail = Loader(lazy(() => import('src/content/pages/Authentication/VerifyEmail')));

//Lockers
const Reserve = Loader(lazy(() => import('src/content/pages/Lockers/Reserve')));

// Status
const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
//const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')));
// const StatusComingSoon = Loader(lazy(() => import('src/content/pages/Status/ComingSoon')));
// const StatusMaintenance = Loader(lazy(() => import('src/content/pages/Status/Maintenance')));


const routes: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'overview',
        element: (
          <Navigate
            to="/"
            replace
          />
        )
      },
      {
        path: 'verify',
        element: <VerifyEmail />
      },
      {
        path: 'reserve',
        element: <Reserve />
      },
      {
        path: '*',
        element: <Status404 />
      },
    ]
  },
  {
    path: 'dashboard',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/dashboard"
            replace
          />
        )
      },
    ]
  },
];

export default routes;
